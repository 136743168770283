.app-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.app-logo {
  width: 55px;
}

.app-content {
  padding: 15px;
  background-color: #ffffff;
  height: 615px;
  width: 1120px;
}

body {
  background-color: #001f3d;
}
