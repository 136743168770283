.agreement-outter-box {
  height: 421px;
  background-color: #fafafa;
  padding: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: #e2e3e4;
  border-radius: 4px;
}

.agreement-inner-box {
  height: 338px;
  background-color: #ffffff;
  overflow: auto;
  border-style: solid;
  border-width: 1px;
  border-color: #e6e6e6;
  border-radius: 4px;
}

.agreement-container {
  padding: 15px;
  word-wrap: normal;
}

.intro-text {
  margin-top: 15px;
}

.download-button {
  margin-top: 20px;
}

.agreement-title {
  font-size: 20px;
  font-weight: bold;
}

.agreement-checkbox {
  margin-top: 10px;
}

.paragraph-indent-level-one {
  margin-left: 20px;
}

.paragraph-indent-level-two {
  margin-left: 40px;
}

.paragraph-indent-level-three {
  margin-left: 60px;
}
